import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import fileDownload from 'js-file-download'
import ApiClient from '../util/apiClient'
import encryptUtil from '../util/encryption'
import numberUtil from '../util/number'
import stringUtil from '../util/string'

export const initialState = {
	getMemberFacilitiesStatus: null,
	getMemberContractsStatus: null,
	postTerminateContractStatus: null,
	postCalculateNextPaymentStatus: null,
	postUnlockFacilityStatus: null,
	getInvoicesByPersonIdStatus: null,
	postChargeInvoiceStatus: null,
	putInvoiceStatus: null,
	postCreateSetupIntentStatus: null,
	putSetDefaultPaymentMethodStatus: null,
	getPersonPaymentV2Status: null,
	getPersonIvStatus: null,
	getContractDocumentsStatus: null,
	memberFacilities: [],
	memberContracts: [],
	memberSince: null,
	memberInvoices: null,
	nextPayment: {},
	billing: {
		default_payment_method_id: null,
		payment_method: {
			last_4_digits: null,
			expiration_month: null,
			expiration_year: null,
			card_type: null
		},
		stripe_customer_id: null,
		si_secret: null,
		stripe_options: {
			clientSecret: null,
			appearance: {
				/*...*/
			}
		}
	},
	person_iv: null,
	onboard_status: null,
	digitalKeys: [],
	getDigitalKeysStatus: null,
	postNotificationStatus: null,
	postMembershipTerminateStatus: null,
	membership: [],
	getPersonMembershipStatus: null,
	insurance: [],
	getPersonInsuranceStatus: null,
	postInsuranceStatus: null,
	facilityInsurance: [],
	getFacilityInsuranceStatus: null,
	getOrganizationKitPersonStatus: null,
	getOrganizationKitFacilityStatus: null,
	facilityOrganizationKits: [],
	personOrganizationKits: [],
	postOrganizationKitStatus: null,
	contractLockCode: null,
	getContractLockCodeStatus: null,
	putContractLockCodeStatus: null,
	postVerifyLockCodeOTPStatus: null,
	postSendLockCodeOTPStatus: null
}

export const getMemberFacilities = createAsyncThunk(
	'dashboard/getMemberFacilities',
	async (phoneNumber) => {
		const apiClient = new ApiClient()
		return await apiClient
			.getv2(`/facility/v3/person/${phoneNumber}`, {
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache',
				Expires: '0'
			})
			.then((resp) => resp)
			.catch((error) => {
				throw new Error(error)
			})
	}
)

export const getPersonFacilities = createAsyncThunk(
	'dashboard/getPersonFacilities',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.getv2(`/person/${id}/facilities`, {
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache',
				Expires: '0'
			})
			.then((resp) => resp)
			.catch((error) => {
				throw new Error(error)
			})
	}
)

export const getMemberContracts = createAsyncThunk(
	'dashboard/getMemberContracts',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/contract/v2/person/id/${id}`, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postTerminateContract = createAsyncThunk(
	'dashboard/postTerminateContract',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/contract/terminate`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postCalculateNextPayment = createAsyncThunk(
	'dashboard/postCalculateNextPayment',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/payment/calculateNextPayment`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postUnlockFacilityV5 = createAsyncThunk(
	'dashboard/postUnlockFacilityV5',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/facility/v5/unlock`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getInvoicesByPersonId = createAsyncThunk(
	'dashboard/getPersonInvoices',
	async (data) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(
				`invoice/person/${data.id}?sort=${data.sort ? data.sort : 'ASC'}`,
				null,
				null
			)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const putInvoice = createAsyncThunk(
	'dashboard/putInvoice',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put(`invoice`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postChargeInvoice = createAsyncThunk(
	'dashboard/postChargeInvoice',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(
				`payment/chargeInvoice`,
				{ 'x-idempotence-key': numberUtil.getUUID() },
				body
			)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postCreateSetupIntent = createAsyncThunk(
	'dashboard/post/payment/createSetupIntent',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/payment/createSetupIntent', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const putSetDefaultPaymentMethod = createAsyncThunk(
	'dashboard/put/setDefaultPaymentMethod',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put('/payment/setDefaultPaymentMethod', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getPersonPaymentV2 = createAsyncThunk(
	'dashboard/get/payment/v2/person',
	async (personId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/payment/v2/person/${personId}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getPersonIv = createAsyncThunk(
	'dashboard/get/person/data',
	async (personId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/person/data/${personId}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getContractDocuments = createAsyncThunk(
	'dashboard/getContractDocuments',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/contract/documents/${body?.contractId}`, { responseType: 'blob' })
			.then((resp) => {
				return {
					data: resp,
					fileName: body?.fileName
				}
			})
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getDigitalKeys = createAsyncThunk(
	'dashboard/getDigitalKeys',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/person/${id}/keys`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postNotification = createAsyncThunk(
	'dashboard/postNotification',
	async (data) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/notification', null, data)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postMembershipTerminate = createAsyncThunk(
	'dashboard/postMembershipTerminate',
	async (data) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/membership/terminate', null, data)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getPersonMembership = createAsyncThunk(
	'dashboard/getPersonMembership',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/membership/person/${id}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getPersonInsurance = createAsyncThunk(
	'dashboard/getPersonInsurance',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/insurance/person/${id}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postInsurace = createAsyncThunk(
	'dashboard/postInsurance',
	async (data) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/insurance`, null, data)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getFacilityInsurance = createAsyncThunk(
	'dashboard/getFacilityInsurance',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/insurance/facility/${id}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getOrganizationKitPerson = createAsyncThunk(
	'dashboard/getOrganizationKitPerson',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/organization_kit/person/${id}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getOrganizationKitFacility = createAsyncThunk(
	'dashboard/getOrganizationKitFacility',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`organization_kit/facility/${id}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postOrganizationKit = createAsyncThunk(
	'dashboard/postOrganizationKit',
	async (data) => {
		const apiClient = new ApiClient()
		const { id, body } = data
		return await apiClient
			.post(`/contract/${id}/organization_kit`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getContractLockCode = createAsyncThunk(
	'dashboard/getContractLockCode',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/contract/${id}/lock_code`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const putContractLockCode = createAsyncThunk(
	'dashboard/putContractLockCode',
	async (data) => {
		const apiClient = new ApiClient()
		const { id, body } = data
		return await apiClient
			.put(`/contract/${id}/lock_code`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const sendLockCodeOTP = createAsyncThunk(
	'dashboard/sendLockCodeOTP',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/person/send-onboarding-otp', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const verifyLockCodeOTP = createAsyncThunk(
	'dashboard/verifyLockCodeOTP',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/person/verify-onboarding-otp', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const memberDashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		resetGetMemberFacilitiesStatus: (state) => {
			state.getMemberFacilitiesStatus = null
		},
		resetGetMemberContractsStatus: (state) => {
			state.getMemberContractsStatus = null
		},
		resetPostTerminateContractStatus: (state) => {
			state.postTerminateContractStatus = null
		},
		resetPostCalculateNextPaymentStatus: (state) => {
			state.postCalculateNextPaymentStatus = null
		},
		resetPostUnlockFacilityStatus: (state) => {
			state.postUnlockFacilityStatus = null
		},
		resetGetInvoicesByPersonIdStatus: (state) => {
			state.getInvoicesByPersonIdStatus = null
		},
		resetPostChargeInvoiceStatus: (state) => {
			state.postChargeInvoiceStatus = null
		},
		resetPutInvoiceStatus: (state) => {
			state.putInvoiceStatus = null
		},
		resetPostCreateSetupIntentStatus: (state) => {
			state.postCreateSetupIntentStatus = null
		},
		resetPutSetDefaultPaymentMethodStatus: (state) => {
			state.putSetDefaultPaymentMethodStatus = null
		},
		resetGetPersonPaymentV2Status: (state) => {
			state.getPersonPaymentV2Status = null
		},
		resetGetPersonIvStatus: (state) => {
			state.getPersonIvStatus = null
		},
		resetMemberInvoices: (state) => {
			state.memberInvoices = null
		},
		setPaymentMethod: (state, { payload }) => {
			state.billing.default_payment_method_id = payload
		},
		resetGetContractDocumentsStatus: (state) => {
			state.getContractDocumentsStatus = null
		},
		resetGetDigitalKeysStatus: (state) => {
			state.getDigitalKeysStatus = null
		},
		resetPostNotificationStatus: (state) => {
			state.postNotificationStatus = null
		},
		resetPostMembershipTerminateStatus: (state) => {
			state.postMembershipTerminateStatus = null
		},
		resetGetPersonMembershipStatus: (state) => {
			state.getPersonMembershipStatus = null
		},
		resetGetPersonInsuranceStatus: (state) => {
			state.getPersonInsuranceStatus = null
		},
		resetPostInsuranceStatus: (state) => {
			state.postInsuranceStatus = null
		},
		resetGetFacilityInsuranceStatus: (state) => {
			state.getFacilityInsuranceStatus = null
		},
		resetGetOrganizationKitPersonStatus: (state) => {
			state.getOrganizationKitPersonStatus = null
		},
		resetGetOrganizationKitFacilityStatus: (state) => {
			state.getOrganizationKitFacilityStatus = null
		},
		resetPostOrganizationKitStatus: (state) => {
			state.postOrganizationKitStatus = null
		},
		resetGetContractLockCodeStatus: (state) => {
			state.getContractLockCodeStatus = null
		},
		resetPutContractLockCodeStatus: (state) => {
			state.putContractLockCodeStatus = null
		},
		resetContractLockCode: (state) => {
			state.contractLockCode = null
		},
		resetPostSendLockCodeOTPStatus: (state) => {
			state.postSendLockCodeOTPStatus = null
		},
		resetPostVerifyLockCodeOTPStatus: (state) => {
			state.postVerifyLockCodeOTPStatus = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getMemberFacilities.pending, (state, { payload }) => {
				state.getMemberFacilitiesStatus = 'PENDING'
			})
			.addCase(getMemberFacilities.fulfilled, (state, { payload }) => {
				state.getMemberFacilitiesStatus = 'FULFILLED'
				state.memberFacilities = payload
			})
			.addCase(getMemberFacilities.rejected, (state, action) => {
				state.getMemberFacilitiesStatus = 'REJECTED'
			})
			.addCase(getPersonFacilities.pending, (state, { payload }) => {
				state.getMemberFacilitiesStatus = 'PENDING'
			})
			.addCase(getPersonFacilities.fulfilled, (state, { payload }) => {
				state.getMemberFacilitiesStatus = 'FULFILLED'
				state.memberFacilities = payload
			})
			.addCase(getPersonFacilities.rejected, (state, action) => {
				state.getMemberFacilitiesStatus = 'REJECTED'
			})
			.addCase(getMemberContracts.pending, (state, { payload }) => {
				state.getMemberContractsStatus = 'PENDING'
			})
			.addCase(getMemberContracts.fulfilled, (state, { payload }) => {
				state.getMemberContractsStatus = 'FULFILLED'
				state.memberContracts = payload.contracts.sort((a, b) => a.id - b.id)
				state.memberSince = payload.member_since
				state.onboard_status = payload.onboard_status
			})
			.addCase(getMemberContracts.rejected, (state, action) => {
				state.getMemberContractsStatus = 'REJECTED'
			})
			.addCase(postTerminateContract.pending, (state, { payload }) => {
				state.postTerminateContractStatus = 'PENDING'
			})
			.addCase(postTerminateContract.fulfilled, (state, { payload }) => {
				state.postTerminateContractStatus = 'FULFILLED'
			})
			.addCase(postTerminateContract.rejected, (state, action) => {
				state.postTerminateContractStatus = 'REJECTED'
			})
			.addCase(postCalculateNextPayment.pending, (state, { payload }) => {
				state.postCalculateNextPaymentStatus = 'PENDING'
			})
			.addCase(postCalculateNextPayment.fulfilled, (state, { payload }) => {
				state.postCalculateNextPaymentStatus = 'FULFILLED'
				state.nextPayment = payload
			})
			.addCase(postCalculateNextPayment.rejected, (state, action) => {
				state.postCalculateNextPaymentStatus = 'REJECTED'
			})
			.addCase(postUnlockFacilityV5.pending, (state, { payload }) => {
				state.postUnlockFacilityStatus = 'PENDING'
			})
			.addCase(postUnlockFacilityV5.fulfilled, (state, { payload }) => {
				state.postUnlockFacilityStatus = 'FULFILLED'
			})
			.addCase(postUnlockFacilityV5.rejected, (state, action) => {
				state.postUnlockFacilityStatus = 'REJECTED'
			})
			.addCase(getInvoicesByPersonId.pending, (state, { payload }) => {
				state.getInvoicesByPersonIdStatus = 'PENDING'
			})
			.addCase(getInvoicesByPersonId.fulfilled, (state, { payload }) => {
				state.getInvoicesByPersonIdStatus = 'FULFILLED'
				state.memberInvoices = payload
			})
			.addCase(getInvoicesByPersonId.rejected, (state, action) => {
				state.getInvoicesByPersonIdStatus = 'REJECTED'
			})
			.addCase(putInvoice.pending, (state, { payload }) => {
				state.putInvoiceStatus = 'PENDING'
			})
			.addCase(putInvoice.fulfilled, (state, { payload }) => {
				state.putInvoiceStatus = 'FULFILLED'
			})
			.addCase(putInvoice.rejected, (state, action) => {
				state.putInvoiceStatus = 'REJECTED'
			})
			.addCase(postChargeInvoice.pending, (state, { payload }) => {
				state.postChargeInvoiceStatus = 'PENDING'
			})
			.addCase(postChargeInvoice.fulfilled, (state, { payload }) => {
				state.postChargeInvoiceStatus = 'FULFILLED'
			})
			.addCase(postChargeInvoice.rejected, (state, action) => {
				state.postChargeInvoiceStatus = 'REJECTED'
			})
			.addCase(postCreateSetupIntent.pending, (state, { payload }) => {
				state.postCreateSetupIntentStatus = 'PENDING'
			})
			.addCase(postCreateSetupIntent.fulfilled, (state, { payload }) => {
				state.postCreateSetupIntentStatus = 'FULFILLED'
				state.billing.si_secret = payload.client_secret
				state.billing.stripe_options.clientSecret = payload.client_secret
			})
			.addCase(postCreateSetupIntent.rejected, (state, action) => {
				state.postCreateSetupIntentStatus = 'REJECTED'
			})
			.addCase(putSetDefaultPaymentMethod.pending, (state, { payload }) => {
				state.putSetDefaultPaymentMethodStatus = 'PENDING'
			})
			.addCase(putSetDefaultPaymentMethod.fulfilled, (state, { payload }) => {
				state.putSetDefaultPaymentMethodStatus = 'FULFILLED'
			})
			.addCase(putSetDefaultPaymentMethod.rejected, (state, action) => {
				state.putSetDefaultPaymentMethodStatus = 'REJECTED'
			})
			.addCase(getPersonPaymentV2.pending, (state, { payload }) => {
				state.getPersonPaymentV2Status = 'PENDING'
			})
			.addCase(getPersonPaymentV2.fulfilled, (state, { payload }) => {
				state.getPersonPaymentV2Status = 'FULFILLED'

				try {
					// Decrypt the payload
					const paymentInfo = encryptUtil.decryptObject(
						payload,
						state.person_iv
					)
					// If payment method id exists, save it
					if (!stringUtil.isNullOrUndefinedV2(paymentInfo.payment_method_id)) {
						state.billing.default_payment_method_id =
							paymentInfo.payment_method_id
					}
					// If stripe customer id exists, save it
					if (!stringUtil.isNullOrUndefinedV2(paymentInfo.stripe_customer_id)) {
						state.billing.stripe_customer_id = paymentInfo.stripe_customer_id
					}

					state.billing.payment_method.last_4_digits = paymentInfo.last_4_digits
					state.billing.payment_method.expiration_month = paymentInfo.exp_month
					state.billing.payment_method.expiration_year = paymentInfo.exp_year
					state.billing.payment_method.card_type = paymentInfo.type
				} catch (err) {
					console.log(err)
				}
			})
			.addCase(getPersonPaymentV2.rejected, (state, action) => {
				state.getPersonPaymentV2Status = 'REJECTED'
			})
			.addCase(getPersonIv.pending, (state, { payload }) => {
				state.getPersonIvStatus = 'PENDING'
			})
			.addCase(getPersonIv.fulfilled, (state, { payload }) => {
				state.getPersonIvStatus = 'FULFILLED'
				state.person_iv = Buffer.from(payload, 'base64')
			})
			.addCase(getPersonIv.rejected, (state, action) => {
				state.getPersonIvStatus = 'REJECTED'
			})
			.addCase(getContractDocuments.pending, (state, { payload }) => {
				state.getContractDocumentsStatus = 'PENDING'
			})
			.addCase(getContractDocuments.fulfilled, (state, { payload }) => {
				state.getContractDocumentsStatus = 'FULFILLED'
				fileDownload(payload?.data, payload?.fileName)
			})
			.addCase(getContractDocuments.rejected, (state, action) => {
				state.getContractDocumentsStatus = 'REJECTED'
			})
			.addCase(getDigitalKeys.pending, (state) => {
				state.getDigitalKeysStatus = 'PENDING'
			})
			.addCase(getDigitalKeys.fulfilled, (state, { payload }) => {
				state.getDigitalKeysStatus = 'FULFILLED'
				state.digitalKeys = payload
			})
			.addCase(getDigitalKeys.rejected, (state) => {
				state.getDigitalKeysStatus = 'REJECTED'
			})
			.addCase(postNotification.pending, (state) => {
				state.postNotificationStatus = 'PENDING'
			})
			.addCase(postNotification.fulfilled, (state) => {
				state.postNotificationStatus = 'FULFILLED'
			})
			.addCase(postNotification.rejected, (state) => {
				state.postNotificationStatus = 'REJECTED'
			})
			.addCase(postMembershipTerminate.pending, (state) => {
				state.postMembershipTerminateStatus = 'PENDING'
			})
			.addCase(postMembershipTerminate.fulfilled, (state) => {
				state.postMembershipTerminateStatus = 'FULFILLED'
			})
			.addCase(postMembershipTerminate.rejected, (state) => {
				state.postMembershipTerminateStatus = 'REJECTED'
			})
			.addCase(getPersonMembership.pending, (state) => {
				state.getPersonMembershipStatus = 'PENDING'
			})
			.addCase(getPersonMembership.fulfilled, (state, { payload }) => {
				state.getPersonMembershipStatus = 'FULFILLED'
				state.membership = payload
			})
			.addCase(getPersonMembership.rejected, (state) => {
				state.getPersonMembershipStatus = 'REJECTED'
			})
			.addCase(getPersonInsurance.pending, (state) => {
				state.getPersonInsuranceStatus = 'PENDING'
			})
			.addCase(getPersonInsurance.fulfilled, (state, { payload }) => {
				state.getPersonInsuranceStatus = 'FULFILLED'
				state.insurance = payload
			})
			.addCase(getPersonInsurance.rejected, (state) => {
				state.getPersonInsuranceStatus = 'REJECTED'
			})
			.addCase(postInsurace.pending, (state) => {
				state.postInsuranceStatus = 'PENDING'
			})
			.addCase(postInsurace.fulfilled, (state) => {
				state.postInsuranceStatus = 'FULFILLED'
			})
			.addCase(postInsurace.rejected, (state) => {
				state.postInsuranceStatus = 'REJECTED'
			})
			.addCase(getFacilityInsurance.pending, (state) => {
				state.getFacilityInsuranceStatus = 'PENDING'
			})
			.addCase(getFacilityInsurance.fulfilled, (state, { payload }) => {
				state.getFacilityInsuranceStatus = 'FULFILLED'
				state.facilityInsurance = payload
			})
			.addCase(getFacilityInsurance.rejected, (state) => {
				state.getFacilityInsuranceStatus = 'REJECTED'
			})
			.addCase(getOrganizationKitPerson.pending, (state) => {
				state.getOrganizationKitPersonStatus = 'PENDING'
			})
			.addCase(getOrganizationKitPerson.fulfilled, (state, { payload }) => {
				state.getOrganizationKitPersonStatus = 'FULFILLED'
				state.personOrganizationKits = payload
			})
			.addCase(getOrganizationKitPerson.rejected, (state) => {
				state.getOrganizationKitPersonStatus = 'REJECTED'
			})
			.addCase(getOrganizationKitFacility.pending, (state) => {
				state.getOrganizationKitFacilityStatus = 'PENDING'
			})
			.addCase(getOrganizationKitFacility.fulfilled, (state, { payload }) => {
				state.getOrganizationKitFacilityStatus = 'FULFILLED'
				state.facilityOrganizationKits = payload
			})
			.addCase(getOrganizationKitFacility.rejected, (state) => {
				state.getOrganizationKitFacilityStatus = 'REJECTED'
			})
			.addCase(postOrganizationKit.pending, (state) => {
				state.postOrganizationKitStatus = 'PENDING'
			})
			.addCase(postOrganizationKit.fulfilled, (state) => {
				state.postOrganizationKitStatus = 'FULFILLED'
			})
			.addCase(postOrganizationKit.rejected, (state) => {
				state.postOrganizationKitStatus = 'REJECTED'
			})
			.addCase(getContractLockCode.pending, (state) => {
				state.getContractLockCodeStatus = 'PENDING'
			})
			.addCase(getContractLockCode.fulfilled, (state, { payload }) => {
				state.getContractLockCodeStatus = 'FULFILLED'
				state.contractLockCode = payload
			})
			.addCase(getContractLockCode.rejected, (state) => {
				state.getContractLockCodeStatus = 'REJECTED'
			})
			.addCase(putContractLockCode.pending, (state) => {
				state.putContractLockCodeStatus = 'PENDING'
			})
			.addCase(putContractLockCode.fulfilled, (state) => {
				state.putContractLockCodeStatus = 'FULFILLED'
			})
			.addCase(putContractLockCode.rejected, (state) => {
				state.putContractLockCodeStatus = 'REJECTED'
			})
			.addCase(sendLockCodeOTP.pending, (state) => {
				state.postSendLockCodeOTPStatus = 'PENDING'
			})
			.addCase(sendLockCodeOTP.fulfilled, (state, { payload }) => {
				state.postSendLockCodeOTPStatus = 'FULFILLED'
			})
			.addCase(sendLockCodeOTP.rejected, (state, action) => {
				state.postSendLockCodeOTPStatus = 'REJECTED'
			})
			.addCase(verifyLockCodeOTP.pending, (state) => {
				state.postVerifyLockCodeOTPStatus = 'PENDING'
			})
			.addCase(verifyLockCodeOTP.fulfilled, (state, { payload }) => {
				state.postVerifyLockCodeOTPStatus = 'FULFILLED'
			})
			.addCase(verifyLockCodeOTP.rejected, (state, action) => {
				state.postVerifyLockCodeOTPStatus = 'REJECTED'
			})
	}
})

const { actions, reducer } = memberDashboardSlice
export const {
	resetGetMemberFacilitiesStatus,
	resetGetMemberContractsStatus,
	resetPostTerminateContractStatus,
	resetPostCalculateNextPaymentStatus,
	resetPostUnlockFacilityStatus,
	resetGetInvoicesByPersonIdStatus,
	resetPutInvoiceStatus,
	resetMemberInvoices,
	resetPostChargeInvoiceStatus,
	resetPostCreateSetupIntentStatus,
	resetPutSetDefaultPaymentMethodStatus,
	resetGetPersonPaymentV2Status,
	resetGetPersonIvStatus,
	setPaymentMethod,
	resetGetContractDocumentsStatus,
	resetGetDigitalKeysStatus,
	resetPostNotificationStatus,
	resetPostMembershipTerminateStatus,
	resetGetPersonMembershipStatus,
	resetGetPersonInsuranceStatus,
	resetPostInsuranceStatus,
	resetGetFacilityInsuranceStatus,
	resetGetOrganizationKitFacilityStatus,
	resetGetOrganizationKitPersonStatus,
	resetPostOrganizationKitStatus,
	resetGetContractLockCodeStatus,
	resetPutContractLockCodeStatus,
	resetContractLockCode,
	resetPostSendLockCodeOTPStatus,
	resetPostVerifyLockCodeOTPStatus
} = actions
export default reducer
